import { DataAccess, ResponseMessage } from "../../../dataaccess/data.access";

export interface Season {
    seasonId?: number;
    housingStartDate?: Date;
    housingEndDate?: Date;
    matchOpenDate?: Date;
    matchCloseDate?: Date;
    code?: string;
    descr?: string;
    buildingMsg?: string;

    [index: string]: any;
}

export class SeasonsService {
    public static getSeasons(): Promise<Season[]> {
        return DataAccess.get("/match/season/list.json");
    }

    public static getSeason(seasonId: number): Promise<Season[]> {
        return DataAccess.get(`/match/season/${seasonId}.json`);
    }

    public static deleteSeason(seasonId: number): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/season/${seasonId}.json`);
    }

    public static updateSeason(seasonOnlyChangedFields: Season): Promise<ResponseMessage> {
        return DataAccess.put(`/match/season/${seasonOnlyChangedFields.seasonId}.json`, seasonOnlyChangedFields);
    }

    public static addSeason(season: Season): Promise<ResponseMessage> {
        return DataAccess.post(`/match/season/new.json`, season);
    }
}
