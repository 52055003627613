import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Season } from "./seasons.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-season")
@Component({
    selector: "s25-ng-season",
    template: `
        @if (init) {
            @if (season.seasonId) {
                <div>Season Id: {{ season.seasonId }}</div>
            }
            <div class="c-margin-top--single">
                <label>
                    Season Code
                    <input class="c-input" type="text" [(ngModel)]="season.code" />
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Housing Start Date
                    <s25-datepicker-inline [(date)]="season.housingStartDate"></s25-datepicker-inline>
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Housing End Date
                    <s25-datepicker-inline [(date)]="season.housingEndDate"></s25-datepicker-inline>
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Match Season Open Date
                    <s25-datepicker-inline [(date)]="season.matchOpenDate"></s25-datepicker-inline>
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Match Season Close Date
                    <s25-datepicker-inline [(date)]="season.matchCloseDate"></s25-datepicker-inline>
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Description
                    <textarea class="cn-form__control" [(ngModel)]="season.descr"></textarea>
                </label>
            </div>
            <div class="c-margin-top--single">
                <label>
                    Building Message
                    <textarea class="cn-form__control" [(ngModel)]="season.buildingMsg"></textarea>
                </label>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonComponent implements OnInit {
    @Input() season: Season;
    init = false;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }
}
