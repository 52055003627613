import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Util } from "../../../util/s25-util";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { MatchProfile, MatchStudentHousing, Question, StudentHousingService } from "../student.housing.service";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { Season } from "../seasons/seasons.service";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import { RuleTreeService } from "../../../services/rule.tree.service";
import { S25RuleTreeUtil } from "../../s25-rule-tree/s25.rule.tree.util";
import { Table } from "../../s25-table/Table";
import { S25TableComponent } from "../../s25-table/s25.table.component";

export type StudentHousingAdminMode =
    | "initialize"
    | "seasons"
    | "buildings"
    | "form"
    | "matchRules"
    | "rollover"
    | "profileManagement"
    | "email"
    | "process";

@TypeManagerDecorator("s25-ng-student-housing-admin")
@Component({
    selector: "s25-ng-student-housing-admin",
    template: `
        @if (init) {
            <div>
                <div class="cust-attr-menu">
                    <ul>
                        <li [ngClass]="{ active: mode === 'initialize' }">
                            <a href="javascript:void(0)" (click)="setMode('initialize')">Initialize</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'seasons' }">
                            <a href="javascript:void(0)" (click)="setMode('seasons')">Seasons</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'buildings' }">
                            <a href="javascript:void(0)" (click)="setMode('buildings')">Buildings</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'form' }">
                            <a href="javascript:void(0)" (click)="setMode('form')">Form</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'matchRules' }">
                            <a href="javascript:void(0)" (click)="setMode('matchRules')">Match Groups</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'rollover' }">
                            <a href="javascript:void(0)" (click)="setMode('rollover')">Season Roll Over</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'profileManagement' }">
                            <a href="javascript:void(0)" (click)="setMode('profileManagement')">Profile Management</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'email' }">
                            <a href="javascript:void(0)" (click)="setMode('email')">Email</a>
                        </li>
                        <li [ngClass]="{ active: mode === 'process' }">
                            <a href="javascript:void(0)" (click)="setMode('process')">Process</a>
                        </li>
                    </ul>
                </div>

                <div [hidden]="mode !== 'initialize'">
                    <p class="c-margin-bottom--single">
                        Click Initialize below to create a Student Housing cabinet and folder structure. After
                        initializing, visit the Groups Tool to set up default OLS for Student Housing assignments.
                    </p>
                    <button class="aw-button aw-button--primary ngBlock" (click)="initialize()">Initialize</button>
                </div>

                <div [hidden]="mode !== 'seasons'">
                    <s25-ng-seasons></s25-ng-seasons>
                </div>

                <div [hidden]="mode !== 'buildings'">
                    <s25-ng-student-housing-buildings></s25-ng-student-housing-buildings>
                </div>

                <div [hidden]="mode !== 'form'">
                    <s25-ng-season-dropdown
                        class="ngBlock c-margin-bottom--single"
                        [selectedSeason]="formSeason"
                        (selectedSeasonChange)="onFormSeasonChange($event)"
                    ></s25-ng-season-dropdown>
                    @if (formSeason) {
                        <s25-ng-match-question [seasonId]="formSeason.seasonId"></s25-ng-match-question>
                    }
                </div>

                <div [hidden]="mode !== 'matchRules'">
                    <s25-ng-season-dropdown
                        class="ngBlock c-margin-bottom--single"
                        [selectedSeason]="ruleSeason"
                        (selectedSeasonChange)="onRuleSeasonChange($event)"
                    ></s25-ng-season-dropdown>
                    @if (ruleSeason) {
                        <s25-ng-rule-tree
                            [category]="'match'"
                            [subCategory]="subCategory"
                            [conditionFilterMap]="conditionFilterMap"
                        ></s25-ng-rule-tree>
                    }
                </div>

                <div [hidden]="mode !== 'rollover'">
                    <label
                        >From:
                        <s25-ng-season-dropdown
                            class="ngBlock c-margin-bottom--single"
                            [(selectedSeason)]="rollOverFrom"
                        ></s25-ng-season-dropdown>
                    </label>
                    <label
                        >To:
                        <s25-ng-season-dropdown
                            class="ngBlock c-margin-bottom--single"
                            [(selectedSeason)]="rollOverTo"
                        ></s25-ng-season-dropdown>
                    </label>
                    <button class="aw-button aw-button--primary" (click)="rollOver()">Roll Over</button>
                </div>

                <div [hidden]="mode !== 'profileManagement'">
                    <s25-ng-season-invite></s25-ng-season-invite>
                </div>

                <div [hidden]="mode !== 'email'">
                    <s25-ng-student-housing-email></s25-ng-student-housing-email>
                </div>

                <div [hidden]="mode !== 'process'">
                    <s25-ng-season-process></s25-ng-season-process>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHousingAdminComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    formSeason: Season;
    ruleSeason: Season;
    rollOverFrom: Season;
    rollOverTo: Season;
    conditionFilterMap: Rules.ConditionFilterMap;
    subCategory: string;
    questions: Question[];
    mode: StudentHousingAdminMode = "initialize";
    init = false;

    setMode = (mode: StudentHousingAdminMode = "seasons") => {
        this.mode = mode;
        this.cd.detectChanges();
    };

    onFormSeasonChange = (season: Season) => {
        this.formSeason = null;
        this.cd.detectChanges();
        this.formSeason = season;
        this.cd.detectChanges();
    };

    onRuleSeasonChange = async (season: Season) => {
        this.questions = await StudentHousingService.getQuestions(season.seasonId);
        this.ruleSeason = null;
        this.cd.detectChanges();
        this.ruleSeason = season;
        this.conditionFilterMap = { matchQuestions: "" + season.seasonId };
        this.subCategory = "" + season.seasonId;
        this.cd.detectChanges();
    };

    rollOver = async () => {
        if (!this.rollOverFrom || !this.rollOverTo) return;

        let promises: Promise<any>[] = [];

        // copy buildings from season to season (overwriting any in to-season)
        promises.push(
            StudentHousingService.getStudentBuildings(this.rollOverFrom.seasonId).then((buildings) => {
                StudentHousingService.setStudentBuildings(this.rollOverTo.seasonId, buildings);
            }),
        );

        // copy questions from season to season (overwriting any in to-season)
        // also copy associated rules from season to season, with overwrite
        promises.push(
            StudentHousingService.getQuestions(this.rollOverFrom.seasonId).then(async (fromQuestions) => {
                return StudentHousingService.replaceSeasonQuestions(
                    this.rollOverTo.seasonId,
                    S25Util.deepCopy(fromQuestions).map((q) => {
                        q.seasonId = this.rollOverTo.seasonId;
                        q.options?.forEach((opt) => {
                            opt.seasonId = this.rollOverTo.seasonId;
                        });
                        return q;
                    }),
                );
            }),
        );

        promises.concat(this.rollOverRules("match", this.rollOverFrom.seasonId, this.rollOverTo.seasonId));
        promises.concat(this.rollOverRules("matchForm", this.rollOverFrom.seasonId, this.rollOverTo.seasonId));

        // if roll over to season is the same as the selected season for rules, we should update the
        // questions that the rules season is using
        if (this.rollOverTo.seasonId === this.ruleSeason?.seasonId) {
            this.questions = await StudentHousingService.getQuestions(this.rollOverTo.seasonId);
        }

        await S25Util.all(promises);
        this.cd.detectChanges();
    };

    rollOverRules = async (category: Rules.Category, rollOverFromSeasonId: number, rollOverToSeasonId: number) => {
        let promises: Promise<any>[] = [];

        const toSeasonRawRules = await RuleTreeService.getRules(category, true, rollOverToSeasonId);
        const toSeasonRules = S25RuleTreeUtil.parseRules(toSeasonRawRules);

        // delete rules assoc with the current (original) to-season
        for (const rule of toSeasonRules) {
            promises.push(RuleTreeService.delRule(rule.id));
        }

        // wait for questions and rule dels to be done before making new rules for the new set of questions
        await S25Util.all(promises);

        // form newToRules using fromRules
        const fromSeasonRawRules = await RuleTreeService.getRules(category, true, rollOverFromSeasonId);
        const fromSeasonRules = S25RuleTreeUtil.parseRules(fromSeasonRawRules);
        const newToRules = [].concat(fromSeasonRules);
        newToRules.forEach((rule) => {
            rule.id = -1 * Math.ceil((Math.random() + 1) * 100000000);
            rule.subCategory = "" + rollOverToSeasonId;
            rule.name = "S" + rollOverToSeasonId + ": " + rule.name.replace(/^S\d+:\s+/, "");
            promises.push(RuleTreeService.putParsedRule(rule));
        });

        await S25Util.all(promises);
    };

    initialize = async () => {
        await StudentHousingService.initialize();
    };

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }
}
